/* iPhone 6 portrait */
@media only screen
	and (min-device-width: 375px)
	and (max-device-width: 667px)
	and (orientation: portrait) {

}

/* iPhone 6 landscape */
@media only screen
	and (min-device-width: 375px)
	and (max-device-width: 667px)
	and (orientation: landscape) {

}

/* iPhone 6+ portrait */
@media only screen
	and (min-device-width: 414px)
	and (max-device-width: 736px)
	and (orientation: portrait) {

}

/* iPhone 6+ landscape */
@media only screen
	and (min-device-width: 414px)
	and (max-device-width: 736px)
	and (orientation: landscape) {

}

/* iPhone 6 and iPhone 6+ portrait and landscape */
@media only screen
	and (max-device-width: 640px), only screen
	and (max-device-width: 667px), only screen
	and (max-width: 480px) {

	.slides {

		a.slides-navigation {
			display: none;
		}

		section {

			div.innersection {
				margin: 0;
				width: 100%;
				left: 0;

				img {
					width: 100%;
					height: auto;
				}
			}
		}
	}
}

/* iPhone 6 and iPhone 6+ portrait */
@media only screen
	and (max-device-width: 640px), only screen
	and (max-device-width: 667px), only screen
	and (max-width: 480px)
	and (orientation: portrait){

}

/* iPhone 6 and iPhone 6+ landscape */
@media only screen
	and (max-device-width: 640px), only screen
	and (max-device-width: 667px), only screen
	and (max-width: 480px)
	and (orientation: landscape){

}