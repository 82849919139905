.slides {
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: hidden;
	box-sizing: border-box;

	*, *:before, *:after {
		box-sizing: border-box;
	}

	img {
		border: 0;
		margin: 0;
	}

	section {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		perspective: 1000;

		&.animated {
			transition: transform 770ms cubic-bezier(0.225, 0.5, 0.165, 1);
		}

		&.quick {
			transition: transform 300ms;
		}

		&:nth-child(1),
		&.onscreen {
			transform: translate(0);
		}

		&.left {
			transform: translateX(-100%);
		}

		&,
		&.right {
			transform: translateX(100%);
		}

		div.frontside, div.backside {
			background-color: white;
			position: absolute;
			top: 0;
			left: 50%;
			width: 900px;
			height: 100%;
			margin-left: -450px;
		}

		div.frontside {
			backface-visibility: hidden;
			z-index: 7;
			transform: rotateY(0deg);
		}

		div.backside {
			z-index: 6;
			transform: rotateY(180deg);
		}
	}

	.slides-dots {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 20px;
		z-index: 10;
		text-align: center;

		a {
			display: inline-block;
			width: 20px;
			height: 20px;
			text-decoration: none;

			&:after {
				content: "\f10c";
				font-family: FontAwesome;
				color: white;
				font-size: 12px;
				line-height: 150%;
			}

			&:hover {
				&:after {
					content: "\f192";
				}
			}

			&.selected:after {
				content: "\f111";
			}
		}
	}

	a.slides-navigation {
		display: block;
		text-decoration: none;
		color: white;
		opacity: 0.5;
		font-size: 3em;
		position: absolute;

		&.previous {
			top: 45%;
			left: 10px;
		}

		&.next {
			top: 45%;
			right: 10px;
		}
	}
}