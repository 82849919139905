@import "../../Fonts/font-awesome-4.3.0/css/font-awesome.css";
@import "_mixins";
@import "_slides";
@import "_iphone6";

html, body {
	height: 100%;
	width: 100%;
}

body {
	font-family: 'Open Sans', sans-serif;
	font-size: 65.2%;
	overflow: hidden;
	background: #333333;
	background: linear-gradient(to bottom, hsla(0,0%,20%,1) 0%, hsla(0,0%,50%,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#333333', endColorstr='#7f7f7f',GradientType=0 );
	margin: 0;
	padding: 0;
	position: relative;
}

#slideshow {
	z-index: 5;
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -300px;
	width: 100%;
	height: 600px;
}

p.disclaimer {
	text-transform: uppercase;
	letter-spacing: .1em;
	width: 900px;
	position: absolute;
	bottom: 0;
	left: 50%;
	padding: 5px;
	margin-top: 0;
	margin-right: 0;
	margin-bottom: 0;
	margin-left: -450px;
	text-align: left;
	color: white;
	font-size: 1em;
	opacity: 0.5;
}

